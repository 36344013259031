import React from "react"
import Main from "./components/Main"

function App() {
  return (
    <div>
      <Main />
    </div>
  )
}

export default App